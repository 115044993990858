import * as React from "react"
import { Helmet } from "react-helmet"

const Error = () => (
  <div>
    <Helmet>
      <style>{"body { background-color: white; }"}</style>
    </Helmet>
  </div>
)

export default Error
